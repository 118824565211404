<template>
	<div>
		<div class="menu-body-overlay" :class="{ active: showMenu }" @click="hideMenu" />

		<nav class="top-nav change-on-scroll" :class="{ subpage: slug !== 'index' }">
			<div class="row">
				<div class="columns column12">
					<div class="border change-on-scroll" />
					<div class="text-nav">
						<nuxt-link
							:to="{ path: localePath('/', locale), hash: '#top' }"
							class="logo change-on-scroll"
						/>
						<div class="menu-buttons change-on-scroll">
							<a class="book-button nav-buttons reserve-now desktop-only" href="#ft-open">
								<span class="button-mobile">{{ $t('bookATable') }}</span>
							</a>
							<div class="menu-button nav-buttons" @click.prevent="openMenu">
								<div class="bar" />
								<div class="bar" />
								<div class="bar" />
							</div>
						</div>
					</div>
					<div class="fullscreen-nav" :class="{ active: showMenu }">
						<div class="menu-section">
							<div class="menu-close" @click="hideMenu">
								<span class="close-icon" />
							</div>
							<ul class="main-nav">
								<li v-for="item in topmenu[locale]" :key="item.filename">
									<nuxt-link
										:exact="item.slug === 'index'"
										:to="`${item.filename}`"
										@click="hideMenu"
									>
										{{ item.header }}
									</nuxt-link>
								</li>
							</ul>
							<social-media theme="dark" :socials="socials" />
							<div class="fullscreen-nav-footer">
								<div class="nav-footer-menu">
									<nuxt-link
										v-for="item in footermenu[locale]"
										:key="item.filename"
										:to="item.filename"
										@click="hideMenu"
									>
										{{ item.header }}
									</nuxt-link>
								</div>
								<div v-if="page" class="lang-nav">
									<nuxt-link
										v-for="item in page.langmenu"
										:key="`${item.filename}-${item.language}`"
										:to="`${item.filename}`"
										@click="hideMenu"
									>
										<span>{{ item.language }}</span>
									</nuxt-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<header v-if="!(page && page.headingImages && page.headingImages.length)" class="no-slider" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { socials } = useDefaults();
const { locale } = useI18n();

await fetchPage();

defineProps({
	slug: { type: String, default: '' },
});

const showMenu = ref(false);

const { data: footermenu } = await useWebsiteFetch('footermenu');
const { data: topmenu } = await useWebsiteFetch('topmenu');

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	const colorChange = document.querySelectorAll('.change-on-scroll');
	if (window.scrollY >= 50) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
	if (window.scrollY >= 610) {
		colorChange.forEach((el) => el.classList.add('dark'));
	} else {
		colorChange.forEach((el) => el.classList.remove('dark'));
	}
};

const openMenu = () => {
	showMenu.value = true;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
header.no-slider {
	width: 100%;
	height: 125px;
}

.locked .top-nav {
	z-index: 101;
}

.button-mobile {
	vertical-align: middle;
}

.border {
	background: #f7f5eb;
	width: 100%;
	height: 1px;
	margin: 0 0 20px;
	transition: all 0.3s ease-in-out;

	&.dark {
		background: $primary-color;
	}
}

.top-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 18;
	padding: 20px 0;
	transition: background 0.3s ease-in-out;

	&.dark {
		background: #f7f5eb;
		padding: 10px 0;

		.border {
			height: 0;
			margin: 0;
		}
	}

	&.subpage {
		background: #f7f5eb;
		padding: 10px 0;

		.logo {
			background: url('~/assets/images/logo-demark-text-black.svg') center center no-repeat;
			background-size: 130px;
		}
	}

	.row {
		overflow: visible;
		max-width: 100vw;
	}

	.logo {
		background: url('~/assets/images/logo-demark-text-white.svg') center center no-repeat;
		background-size: 130px;
		width: 130px;
		height: 38px;
		float: left;
		z-index: 5;
		position: relative;

		&.dark {
			background: url('~/assets/images/logo-demark-text-black.svg') center center no-repeat;
			background-size: 130px;
		}
	}
}

.menu-body-overlay {
	display: none;
	position: fixed;
	inset: 0;
	z-index: 99;

	&.active {
		display: block;
	}
}

.menu-buttons {
	display: flex;
	flex-flow: row nowrap;
	gap: 20px;
}

.menu-close {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	padding-top: 40px;
	cursor: pointer;

	.close-icon {
		background: url('~/assets/images/menu-close-icon.png') center center no-repeat;
		background-size: 30px;
		width: 30px;
		height: 30px;
		display: block;
	}
}

.text-nav {
	z-index: 1;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

.book-button {
	color: #fff;
	background: $primary-color;
	cursor: pointer;
	font-family: 'courier_newregular', MessinaSerif, serif;
	line-height: 20px;
	padding: 14px 25px 7px;
	text-decoration: none;

	&:hover {
		background: $primary-color-hover;
	}
}

.nav-button-title {
	position: absolute;
	right: 100%;
	width: 0;
	opacity: 0;
	color: #000;
	font-size: $secondary-font-size;
}

.nav-buttons:hover .nav-button-title {
	opacity: 1;
	width: auto;
	white-space: nowrap;
	margin-right: 10px;
	transition: all 0.4s ease-out;
}

.menu-button {
	display: flex;
	flex-flow: column wrap;
	margin: 4px 0 0 10px;
	cursor: pointer;

	.bar {
		width: 44px;
		height: 1px;
		background: #f7f5eb;
		transition: all 0.3s ease-in-out;

		&:first-child {
			margin: 0 0 15px;
		}

		&:last-child {
			margin: 15px 0 0;
		}
	}
}

.subpage {
	.border,
	.bar {
		background: #000;
	}
}

.dark {
	.bar {
		background: #000;
	}
}

.fullscreen-nav {
	position: fixed;
	top: 0;
	right: 0;
	background: $primary-color;
	z-index: 100;
	width: 100%;
	max-width: 700px;
	height: 100%;
	opacity: 0;
	padding: 0 40px 40px;
	transition: all 0.5s ease-in-out;
	transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);

	&.active {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}

	.socials {
		width: 100%;
		margin: 30px 0 0;

		> a {
			font-size: 22px;
		}
	}

	.main-nav {
		text-align: left;
		position: relative;
		width: 100%;

		li {
			display: block;
			font-size: 34px;
			margin: 8px 0;
			text-transform: none;
		}

		a {
			color: #fff;
			position: relative;
			padding: 0 0 2px;
			text-decoration: none;
			font-size: 34px;

			&:hover,
			&.active {
				color: #000;
			}
		}
	}
}

.fullscreen-nav-footer {
	position: absolute;
	bottom: 75px;
	left: 40px;
	right: 40px;
	font-family: 'courier_newregular', MessinaSerif, serif;
	font-size: 14px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;

	.nav-footer-menu {
		display: flex;
		flex-flow: row wrap;

		a {
			margin: 0 25px 0 0;
			color: #fff;
			text-decoration: none;

			&:hover,
			&.active {
				color: #000;
			}
		}
	}

	.lang-nav {
		display: flex;
		flex-flow: row wrap;
		margin: 10px 0 0;

		a {
			color: #f7f5eb;
			text-transform: uppercase;
			text-decoration: none;

			&:first-child::after {
				content: '';
				width: 48px;
				height: 1px;
				margin: 0 10px 3px;
				background: #fff;
				display: inline-block;
			}

			&.active {
				font-weight: 700;
			}
		}
	}
}

.cta-fullscreen-menu {
	float: left;
	display: block;
}

@media (min-width: 1281px) {
	.fullscreen-nav .main-nav li {
		font-size: 40px;
	}
}

@media (max-width: 960px) {
	.top-nav {
		.columns {
			padding: 0 10px;
		}
	}

	.button-mobile {
		display: initial;
	}

	.nav-buttons:hover,
	.menu-close:hover {
		transform: none;
	}

	.menu-button {
		opacity: 1;
		z-index: 100;
	}

	.book-button {
		opacity: 1;
		z-index: 102;
		top: auto;
		bottom: 0;
		right: 0;
		width: 100%;
		border-radius: 0;
		margin: 0;

		.fa-calendar-days {
			margin-right: 5px;
			font-size: 18px;
		}

		&.fixed {
			top: auto;
		}
	}

	.text-nav .main-nav {
		display: none;
	}
}

@media (max-width: 600px) {
	.top-nav {
		padding: 16px 0;
	}

	.menu-buttons {
		.book-button {
			padding: 10px 25px 5px;
			font-size: 16px;

			&.desktop-only {
				display: none;
			}
		}

		.menu-button {
			margin: 4px 0 0;

			.bar {
				&:first-child {
					margin: 0 0 12px;
				}

				&:last-child {
					margin: 12px 0 0;
				}
			}
		}
	}

	.menu-close {
		padding-top: 34px;
		margin-right: -18px;
	}

	.border {
		margin: 0 0 12px;
	}

	.fullscreen-nav {
		overflow-y: scroll;

		.main-nav {
			width: 100%;

			li {
				font-size: 26px;
			}

			a {
				font-size: 32px;
			}
		}

		.socials {
			margin: 25px 0;
		}

		.right-section {
			float: left;
			position: relative;
			margin: 40px 0 0;
			max-width: 100%;
		}

		.fullscreen-nav-footer {
			position: relative;
			bottom: auto;
			left: auto;
			right: auto;
			flex-flow: column wrap;
			width: 100%;
			align-items: flex-start;

			.lang-nav {
				width: 100%;
				margin: 10px 0 0;
			}

			.nav-footer-menu {
				a {
					margin: 5px 25px 5px 0;
				}
			}
		}
	}

	body.locked {
		overflow-y: auto;
	}
}
</style>
