<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.footerNavigate }}</h3>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
						<a href="#" @click.prevent="openConsent">{{ $t('consentOpen') }}</a>
					</p>
				</div>
				<div class="columns column3">
					<h3>Socials</h3>
					<social-media :socials="socials" />
				</div>
				<div v-if="page" class="columns column3">
					<h3>Language</h3>
					<div class="lang-nav">
						<nuxt-link
							v-for="item in page.langmenu"
							:key="`${item.filename}-${item.language}`"
							:to="`${item.filename}`"
						>
							<span>{{ item.language }}</span>
						</nuxt-link>
					</div>
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.footerContact }}</h3>
					<p>
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span> </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
				</div>
			</div>
		</footer>
		<footer class="bottom-footer">
			<div class="row">
				<div class="columns column12 align-center">
					<a href="https://www.aedes.co/en/" target="_blank">
						<img src="~/assets/images/logo-aedes-white.png" alt="An Aedes Place" loading="lazy" />
					</a>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { defaults, socials, fetchDefaults } = useDefaults();
const { page, fetchPage } = usePage();
const { openConsent } = useCookieConsent();

const { data: footermenu } = await useWebsiteFetch('footermenu');
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: $footer-link-color;
	padding: 75px 0 125px;
	font-size: 16px;
	margin-top: 0;

	p {
		line-height: 30px;
		font-family: 'courier_newregular', MessinaSerif, serif;
	}

	h3 {
		color: #fff;
		margin: 0 0 25px;
	}

	.row {
		display: flex;
		flex-flow: row nowrap;

		.columns {
			width: 25%;
		}
	}

	a {
		color: #fff;

		&:hover {
			color: #000;
		}
	}

	&.bottom-footer {
		background: #000;
		color: #f7f5eb;
		display: flex;
		padding: 25px 0;
		margin: 0;

		.row {
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;

			.columns {
				width: 100%;
			}
		}

		p {
			margin: 0;
		}

		a {
			font-weight: 700;
		}

		img {
			width: 135px;
		}
	}

	.button {
		color: #fff;
		background: $primary-color;

		&:hover {
			color: #fff;
			background: $primary-color-hover;
		}
	}

	.lang-nav {
		display: flex;
		flex-flow: row wrap;

		a {
			color: #f7f5eb;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 13px;

			&:first-child::after {
				content: '';
				width: 48px;
				height: 1px;
				margin: 0 10px 3px;
				background: #fff;
				display: inline-block;
			}

			&.active {
				font-weight: 700;
			}
		}
	}
}

.footer-logo {
	background: url('~/assets/images/logo-demark-text-white.svg') center center no-repeat;
	background-size: 130px;
	width: 130px;
	height: 38px;

	&.black {
		background: url('~/assets/images/logo-demark-text-color.svg') center center no-repeat;
		background-size: 130px;
	}
}

a {
	color: $footer-link-color;
	text-decoration: none;

	&:hover {
		color: $footer-link-color;
	}
}

@media (max-width: 1180px) {
	footer {
		.row {
			flex-flow: row wrap;

			.columns {
				margin: 20px 0;
			}
		}
	}
}

@media (max-width: 900px) {
	footer {
		padding: 50px 0;
		margin-top: 0;

		.socials,
		.lang-nav {
			justify-content: center;
		}

		.row {
			.columns {
				width: 100%;
				margin: 25px auto;
				text-align: center;

				.footer-logo {
					margin: 0 auto;
				}
			}
		}

		&.bottom-footer {
			padding: 15px 0;
			flex-flow: column wrap;

			.row {
				flex-flow: column wrap;

				.columns {
					width: 100%;
					text-align: left !important;
					margin: 15px auto;
				}
			}
		}
	}
}
</style>
