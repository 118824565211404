<template>
	<div class="marquee">
		<div class="marquee-content">
			<slot name="content" />
		</div>
		<div class="marquee-content" aria-hidden="true">
			<slot name="content" />
		</div>
	</div>
</template>

<style scopes>
.marquee {
	--gap: 3rem;

	display: flex;
	overflow: hidden;
	gap: var(--gap);
	padding: 0 0 50px;
}

.marquee-content {
	animation: scroll 35s linear infinite;
	flex-shrink: 0;
	display: flex;
	justify-content: space-around;
	min-width: 100%;
	gap: var(--gap);
}

@keyframes scroll {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(calc(-100% - var(--gap)));
	}
}
</style>
