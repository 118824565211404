<template>
	<div id="top" class="container" :class="{ subpage: page.slug !== 'index' }">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<client-only>
			<component :is="'script'" :key="locale">
				(function (d, s, id, h) { var ftjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; var
				js = d.createElement(s); js.id = id; js.src = 'https://cdn.formitable.com/sdk/v1/ft.sdk.min.js'; h &&
				(js.onload = h); ftjs.parentNode.insertBefore(js, ftjs); })(document, 'script', 'formitable-sdk',
				function () { FT.load('Analytics'); });
			</component>
		</client-only>
		<navigation :slug="page.slug" />
		<heading-image
			v-if="page && page.headingImages && page.headingImages.length"
			:slug="page.slug"
			:heading-image="page.headingImages[0] ?? null"
			:vimeoid="page.vimeoID"
			:defaults="defaults"
		/>
		<slot />
		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:slug="page.slug"
		/>
		<bottomsection
			v-if="page"
			:header="page.bottomSectionTitle"
			:content="page.bottomSectionContent"
			:image="page.bottomSectionImage"
			:contentleft="page.contentBottomLeft"
			:contentright="page.contentBottomRight"
			:imageleft="page.imageBottomLeft"
			:imageright="page.imageBottomRight"
		/>
		<eventsbottomsection v-if="page && page.slug == 'events-index'" :images="defaults.events.events" />
		<prefooter :designtext="page.designText" />
		<footers />
		<cookie-consent v-if="defaults[locale].cookieConsent.published" :item="defaults[locale].cookieConsent" />
		<div
			class="ft-widget-b2"
			data-restaurant="7a76db4a"
			data-open="1500"
			data-open-mobile="false"
			data-color="#AD7C26"
			:data-language="locale"
			data-tag="Website"
			data-toolbar="true"
			data-toolbar-mobile="true"
		/>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.pageYOffset >= 50) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

onUpdated(() => {
	window.FT && window.FT.parse();
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			json: {
				'@context': 'http://schema.org',
				'@type': 'Restaurant',
				'@id': `${config.public.siteUrl}#restaurant`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo-dedurgerdam-black-full.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			},
		},
	],
});
</script>
